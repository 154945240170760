import {
  EditRegistrationProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { redirectToDataPreparationPage } from "@/utils/redirects";
import { FaroButton, FaroTooltip } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  CaptureApiClient,
  RegistrationState,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";
import { enableDefaultEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";
import {
  selectCaptureTreeEdges,
  selectRevisionEntities,
} from "../store/revision-selectors";

interface EditRegistrationButtonProps {
  /** The currently active revision state. Used to determine whether editing is possible. */
  revisionState: RegistrationState;
}

/** @returns a button to toggle the visual registration mode */
export function EditRegistrationButton({
  revisionState,
}: EditRegistrationButtonProps): JSX.Element {
  const dispatch = useAppDispatch();
  const disabledMessage = useEditButtonDisabledMessage(revisionState);

  const { projectApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();
  const { getState } = useAppStore();

  const [isLoading, setIsLoading] = useState(false);

  // Create a new revision which the user can edit and redirect to it
  const createRevisionAndRedirect = useCallback(async () => {
    setIsLoading(true);

    const mainEntities = await projectApiClient.getCaptureTreeForMainRevision();
    const mainEdges =
      await projectApiClient.getAllRegistrationEdgesForMainRevision();

    const mainEntityIds = mainEntities.map((entity) => entity.id);
    const mainEdgeIds = mainEdges.map((edge) => edge.id);

    try {
      // Prepare the new revision
      const newRevision = await projectApiClient.createRegistrationRevision(
        mainEntityIds,
        mainEdgeIds,
        CaptureApiClient.registrationBackend,
      );

      // In this case we want to delete all existing edges in the target revision and use only the ones that are in the current version
      await projectApiClient.markRegistrationEdgeRevisionsAsDeleted(
        newRevision.id,
        mainEdgeIds,
      );

      // Fetch data from current revision and merge it into our new revision:
      const currentRevisionEntities = selectRevisionEntities(getState());
      const currentRevisionEdges = selectCaptureTreeEdges(getState());

      await projectApiClient.mergeRegistrationRevisionData(
        currentRevisionEntities,
        currentRevisionEdges,
        mainEntities,
        newRevision.id,
      );

      redirectToDataPreparationPage({
        projectId: projectApiClient.projectId,
        revisionId: newRevision.id,
      });
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to create new registration",
      });
    } finally {
      setIsLoading(false);
    }
  }, [projectApiClient, getState, handleErrorWithToast]);

  const enableEditing = useCallback(() => {
    Analytics.track<EditRegistrationProperties>(EventType.editRegistration, {
      via: "edit registration button",
    });
    const canEditRevision =
      revisionState !== RegistrationState.merged &&
      revisionState !== RegistrationState.canceled;

    if (canEditRevision) {
      dispatch(enableDefaultEditMode());
    } else {
      createRevisionAndRedirect();
    }
  }, [dispatch, revisionState, createRevisionAndRedirect]);

  return (
    <FaroTooltip title={disabledMessage}>
      <FaroButton
        variant="secondary"
        onClick={enableEditing}
        disabled={!!disabledMessage}
        isLoading={isLoading}
        fullWidth
      >
        Edit registration
      </FaroButton>
    </FaroTooltip>
  );
}

/**
 * @param revisionState The current state of the revision
 * @returns A message to display when the edit button is disabled, or undefined if the button is enabled.
 */
function useEditButtonDisabledMessage(
  revisionState: RegistrationState,
): string | undefined {
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  if (!hasWritePermission) {
    return "You do not have permission to edit, please contact the project administrator.";
  }

  // Using a switch to check if all states are covered
  switch (revisionState) {
    case RegistrationState.cloudRegistrationStarted:
      return "Cannot edit while a registration is in progress.";
    case RegistrationState.started:
    case RegistrationState.userModified:
    case RegistrationState.registered:
    case RegistrationState.canceled:
    case RegistrationState.merged:
      // Editing is allowed
      break;
  }
}
