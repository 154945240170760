import { useLoadProjectArea } from "@/components/common/project-provider/project-loading-context";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { selectSheetForCadAlignment } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { selectSheetForSheetToCloudAlignment } from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  selectActiveArea,
  selectActiveElement,
  selectActiveSheetsIfAvailable,
} from "@/store/selections-selectors";
import {
  setActiveArea,
  setActiveElement,
  setActiveSheets,
} from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericImgSheet,
  IElementGenericStream,
  IElementImg360,
  IElementImgSheet,
  IElementSection,
  IElementTimeSeriesDataSession,
  isIElementGenericImgSheet,
  isIElementImgSheet,
  isIElementSectionDataSession,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import {
  newestToOldest,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  CurrentAreaData,
  CurrentScene,
  WaypointRange,
  computePanoDateRange,
  selectActiveElementReference,
  selectCurrentAreaData,
  selectCurrentScene,
  selectPanoAltitudeRange,
} from "./mode-selectors";

type ModeDataContext = {
  /** All the interesting project elements about the current active area */
  currentArea?: CurrentAreaData;

  /** The elements the user want to navigate in the current area based on the current selection */
  currentScene?: CurrentScene;

  /** True if the area is currently loading */
  isLoading: boolean;

  /**
   * The highest and lowest placeholder altitude in the current scene.
   * Only panorama images from datasets are taken into account
   */
  waypointAltitudeRange?: WaypointRange;

  /** The oldest and newest creation date among all panos in the current scene. */
  waypointDateRange?: WaypointRange;
};

export const ModeDataContext = createContext<ModeDataContext | undefined>(
  undefined,
);

/** @returns a context that determines based on user input and ui state what data each mode should render */
export function ModeDataProvider({ children }: PropsWithChildren): JSX.Element {
  const activeElement = useAppSelector(selectActiveElement);
  assert(
    activeElement,
    "An active element is required to compute what to render in the Sphere Viewer",
  );
  const activeArea = useAppSelector(selectActiveArea);
  const activeCad = useAppSelector(selectActiveCadModel);

  const dispatch = useAppDispatch();

  const currentArea = useAppSelector(
    selectCurrentAreaData(activeElement),
    isEqual,
  );

  // TODO: Remove once the new area navigation ui is implemented
  // https://faro01.atlassian.net/browse/SWEB-5708
  useEffect(() => {
    if (currentArea?.area?.id !== activeArea?.id) {
      dispatch(setActiveArea(currentArea?.area?.id));
    }
  }, [activeArea, currentArea?.area?.id, dispatch]);

  const isLoading = useLoadProjectArea(currentArea?.area?.id);
  const activeSheets = useAppSelector(selectActiveSheetsIfAvailable);
  // TODO: the scene will support more than one active sheet in https://faro01.atlassian.net/browse/CADBIM-1157
  const currentScene = useAppSelector(
    (state) =>
      selectCurrentScene(
        activeElement,
        activeCad,
        activeSheets[0],
        currentArea,
        state.walkMode.shouldUseIntensityData,
      )(state),
    isEqual,
  );

  // Find the highest and lowest waypoint in the current scene
  const waypointAltitudeRange = useAppSelector(
    selectPanoAltitudeRange(currentScene?.panos),
    isEqual,
  );

  const thePanos = currentScene?.panos;

  const waypointDateRange = useMemo(
    () => computePanoDateRange(thePanos),
    [thePanos],
  );

  useEffect(() => {
    // TODO: changing active area should keep persistent list of active areas: https://faro01.atlassian.net/browse/CADBIM-1169
    if (activeSheets[0]?.id !== currentScene?.activeSheet?.id) {
      dispatch(
        setActiveSheets(
          currentScene?.activeSheet?.id ? [currentScene.activeSheet.id] : [],
        ),
      );
    }
  }, [activeSheets, currentScene?.activeSheet?.id, dispatch]);

  const value = useMemo<ModeDataContext>(
    () => ({
      currentArea,
      currentScene,
      isLoading,
      waypointAltitudeRange,
      waypointDateRange,
    }),
    [
      currentArea,
      currentScene,
      isLoading,
      waypointAltitudeRange,
      waypointDateRange,
    ],
  );

  return (
    <ModeDataContext.Provider value={value}>
      {children}
    </ModeDataContext.Provider>
  );
}

/**
 * @returns The data we want to show to the user based on the current user selection if available
 */
export function useCurrentSceneIfAvailable(): CurrentScene | undefined {
  const modeData = useContext(ModeDataContext);
  assert(
    modeData,
    "useCurrentSceneIfAvailable need to be used inside a ModeDataProvider",
  );
  return modeData.currentScene;
}

/**
 * @returns The data we want to show to the user based on the current user selection
 */
export function useCurrentScene(): CurrentScene {
  const currentScene = useCurrentSceneIfAvailable();
  assert(currentScene, "useCurrentScene requires a scene to be available");
  return currentScene;
}

/**
 * @returns The important project data filtered for the user selected area
 */
export function useCurrentAreaIfAvailable(): CurrentAreaData | undefined {
  const modeData = useContext(ModeDataContext);
  assert(modeData, "useCurrentArea need to be used inside a ModeDataProvider");
  return modeData.currentArea;
}

/**
 * @returns The important project data filtered for the user selected area
 */
export function useCurrentArea(): CurrentAreaData {
  const currentArea = useCurrentAreaIfAvailable();
  assert(
    currentArea,
    "useCurrentArea requires at least an area in the project",
  );
  return currentArea;
}

/** @returns true if the current area is loading */
export function useIsCurrentAreaLoading(): boolean {
  return !!useContext(ModeDataContext)?.isLoading;
}

/**
 * @returns The highest and lowest placeholder altitude in the current scene
 */
export function useWaypointAltitudeRange(): WaypointRange | undefined {
  return useContext(ModeDataContext)?.waypointAltitudeRange;
}

/** @returns the range containing all capture dates of all panos in the current scene */
export function useWaypointDateRange(): WaypointRange | undefined {
  return useContext(ModeDataContext)?.waypointDateRange;
}

export type SheetAlignmentType = "sheetToCad" | "sheetToCloud";
/**
 * @param alignType the sheet alignment type
 * @returns The sheet used for in current session of alignment
 */
export function useSheetSelectedForAlignment(
  alignType: SheetAlignmentType,
): IElementGenericImgSheet {
  const sheetId = useAppSelector(
    alignType === "sheetToCad"
      ? selectSheetForCadAlignment
      : selectSheetForSheetToCloudAlignment,
  );
  if (!sheetId) throw new Error("Current sheet for alignment not specified.");

  const sheet = useAppSelector(selectIElement(sheetId));

  if (!sheet || !isIElementGenericImgSheet(sheet)) {
    throw new Error("Current sheet is not a valid IElementGenericImgSheet.");
  }

  return sheet;
}

/**
 * @returns The list of all ImgSheets in a given time series data session
 * @param timeSeriesDataSession The IElement of type TimeSeries and type hint DataSession in which the ImgSheets are located
 */
export function selectAllImgSheetsInTimeSeriesDataSession(
  timeSeriesDataSession: IElementTimeSeriesDataSession | undefined,
) {
  return (state: RootState): IElementImgSheet[] => {
    const allImgSheetsFound: IElementImgSheet[] = [];
    if (timeSeriesDataSession) {
      const sectionDataSessions = selectChildrenDepthFirst(
        timeSeriesDataSession,
        isIElementSectionDataSession,
      )(state);
      for (const sectionDataSession of sectionDataSessions) {
        const imgSheet = selectChildDepthFirst(
          sectionDataSession,
          (el): el is IElementImgSheet => isIElementImgSheet(el),
        )(state);

        if (imgSheet) {
          allImgSheetsFound.push(imgSheet);
        }
      }
    }
    return allImgSheetsFound;
  };
}

/**
 * Compute a valid model to render from a specific set of sessions
 *
 * @param validSessions the valid sessions
 * @param validModelGuard a guard to identify a valid model to render
 * @param currentModel the current active model
 * @param referenceElement the element identifying the current active time point
 * @returns the valid model to render in the valid sessions if one exists
 */
function selectValidModel<ValidModel extends IElement>(
  validSessions: IElementSection[],
  validModelGuard: (el: IElement) => el is ValidModel,
  currentModel?: IElementImg360 | IElementGenericStream,
  referenceElement?: IElementSection,
) {
  return (state: RootState) => {
    if (currentModel && validModelGuard(currentModel)) {
      return currentModel;
    }
    // Search in all the valid sessions for the current mode
    const sortedSessions = validSessions.sort(newestToOldest);

    // If there's a reference element search the closest in time valid session for this mode
    if (referenceElement) {
      const closestInTime = pickClosestInTime(referenceElement, sortedSessions);
      if (closestInTime) {
        return selectChildDepthFirst(closestInTime, validModelGuard)(state);
      }
    }

    // If there's no reference element pick the newest session available
    if (sortedSessions.length) {
      return selectChildDepthFirst(sortedSessions[0], validModelGuard)(state);
    }
  };
}

/**
 * Check if the current selection makes sense for the current mode. If this is the case,
 * updates it to the best possible selection.
 * The current selection makes "sense" if either item in `validSessions` or the activeCad
 * is valid according to `validModelGuard` (i.e. validModelGuard returns true) .
 *
 * @param validSessions list of valid data sessions to render in this mode
 * @param validModelGuard a guard to verify if the current model to render is valid for this mode
 * @param fallback what to do if there's no valid session to use in this mode
 * @returns the main model to render in this mode based on the current selection, or undefined if the current selection needs be adjusted
 */
export function useComputeValidModeElement<ValidModel extends IElement>(
  validSessions: IElementSection[],
  validModelGuard: (el: IElement) => el is ValidModel,
  fallback?: () => void,
): ValidModel | undefined {
  const dispatch = useAppDispatch();
  const area = useCurrentArea();
  const { activeElement, referenceElement, main, cad } = useCurrentScene();

  const validModel = useAppSelector(
    selectValidModel(validSessions, validModelGuard, main, referenceElement),
  );
  const validReference = useAppSelector(
    selectActiveElementReference(validModel, area),
  );

  const validCad = cad && validModelGuard(cad) ? cad : undefined;

  // Update the selected element if the current selection is not valid for the calling mode
  useEffect(() => {
    // The active mode is valid = nothing to do
    if (validModel === main && validReference === activeElement) return;

    if (validModel) {
      if (validModel.id !== activeElement.id) {
        // the active element has changed
        dispatch(setActiveElement(validModel.id));
      }
      return;
    }

    // if the model is not valid, but there is an active cad, we are ok
    if (validCad) return;

    fallback?.();
  }, [
    activeElement,
    dispatch,
    fallback,
    main,
    validModel,
    validReference,
    validCad,
  ]);

  return validModel ?? validCad;
}
