import { useVisiblePlaceholders } from "@/hooks/use-placeholders";
import { useWalkPlaceholderPositions } from "@/hooks/use-walk-placeholder-positions";
import { useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import {
  selectChildrenDepthFirst,
  useAssignClippingPlanes,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { PathGeometry } from "@faro-lotv/lotv";
import { isEqual } from "es-toolkit";
import { useEffect, useMemo, useState } from "react";
import {
  CatmullRomCurve3,
  DoubleSide,
  Mesh,
  Plane,
  RepeatWrapping,
  Texture,
  Vector3,
} from "three";
import { ODOMETRY_PATH_OBJECT_NAME } from "./odometry-path-renderer";
import { WalkPathPlaceholders } from "./walk-path-placeholder-renderer";

// TODO: Move icons (https://faro01.atlassian.net/browse/SWEB-1882)
import PiastrellaSvg from "./VM_Piastrella.svg";
import PiastrellaSvgActive from "./VM_PiastrellaActive.svg";
import PiastrellaSvgHover from "./VM_PiastrellaHover.svg";

type WalkPathRendererProps = {
  /** The currently active panorama image. */
  activePano?: IElementImg360;

  /** The sheet to render the path for */
  sheet?: IElementGenericImgSheet;

  /** The section IElement containing the odometric path. */
  odometricPath: IElementSection;

  /** Whether the odometric path is currently active. */
  isActive: boolean;

  /**
   * Whether the path fade-off (in the distance) is enabled.
   */
  fadeOff?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /**
   * Callback for when the path itself is clicked.
   *
   * @param odometricPath The odometric path being clicked.
   * @param point The point that is being clicked.
   * @param placeholderPoints Array containing the positions of all the placeholders
   */
  onPathClicked(
    odometricPath: IElementSection,
    point: Vector3,
    placeholderPoints: Vector3[],
  ): void;

  /**
   * Callback for when a placeholder for a panorama image is clicked.
   *
   * @param element The panorama image being clicked.
   */
  onPlaceholderClicked(element: IElementImg360): void;
};

/** An offset to move the path below the placeholders so they don't z-fight */
const PATH_PLACEHOLDER_OFFSET = new Vector3(0, 0.01, 0);

/**
 * @returns The odometric path for a video mode capture series.
 */
export function WalkPathRenderer({
  activePano,
  sheet,
  odometricPath,
  isActive,
  fadeOff,
  clippingPlanes,
  onPathClicked,
  onPlaceholderClicked,
}: WalkPathRendererProps): JSX.Element | null {
  const [isHovered, setIsHovered] = useState(false);

  const panos = useAppSelector(
    selectChildrenDepthFirst(odometricPath, isIElementImg360),
    isEqual,
  );

  const points = useWalkPlaceholderPositions(panos, sheet);

  const { visiblePlaceholders, visiblePositions } = useVisiblePlaceholders({
    placeholders: panos,
    positions: points,
    clippingPlanes,
  });

  const { visiblePositionsLocal, geometry } = useMemo(() => {
    // Offset the whole path by the first point location (then use it as group position)
    const visiblePositionsLocal = visiblePositions.map((p) =>
      p.clone().sub(points[0]),
    );
    if (points.length < 2) return { visiblePositionsLocal };

    // Make the path raise slowly at each point so overlapping path sections will not z-fight
    const heightStep = 0.01 / points.length;
    const heightStepPoint = new Vector3();
    const geometry = new PathGeometry(
      new CatmullRomCurve3(
        points.map((p, index) =>
          p
            .clone()
            .sub(points[0])
            .sub(PATH_PLACEHOLDER_OFFSET)
            .add(heightStepPoint.setY(heightStep * index)),
        ),
      ),
      { width: 0.1 },
    );
    return { visiblePositionsLocal, geometry };
  }, [points, visiblePositions]);

  const textureDefault = useSvg(PiastrellaSvg);
  const textureHover = useSvg(PiastrellaSvgHover);
  const textureActive = useSvg(PiastrellaSvgActive);

  let currentTexture: Texture;
  if (isActive) currentTexture = textureActive;
  else if (isHovered) currentTexture = textureHover;
  else currentTexture = textureDefault;

  // Adjust the textures to the current curve
  useEffect(() => {
    for (const tex of [textureDefault, textureHover, textureActive]) {
      tex.wrapS = RepeatWrapping;
      tex.wrapT = RepeatWrapping;
      tex.generateMipmaps = false;
      tex.needsUpdate = true;
    }
  }, [geometry, textureDefault, textureHover, textureActive]);

  const visibilityDistance = useAppSelector(selectVisibilityDistance);

  const [pathRef, setPathRef] = useState<Mesh | null>(null);

  useAssignClippingPlanes(pathRef, clippingPlanes);

  return (
    <group
      position={points[0]}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
    >
      {geometry && (
        <mesh
          name={ODOMETRY_PATH_OBJECT_NAME}
          ref={setPathRef}
          geometry={geometry}
          onClick={(ev) => onPathClicked(odometricPath, ev.point, points)}
        >
          <meshFadeOffMaterial
            transparent
            side={DoubleSide}
            map={currentTexture}
            // TODO: Add toggle to material to disable fade off (https://faro01.atlassian.net/browse/SWEB-1965)
            // eslint-disable-next-line react/no-unknown-property
            endFadeDistance={fadeOff ? visibilityDistance : Infinity}
            // eslint-disable-next-line react/no-unknown-property
            startFadeDistance={0}
            depthWrite={false}
          />
        </mesh>
      )}
      {(isActive || isHovered) && (
        <WalkPathPlaceholders
          activeId={
            activePano ? visiblePlaceholders.indexOf(activePano) : undefined
          }
          placeholders={visiblePositionsLocal}
          fadeOff={fadeOff}
          shouldHighlightActive={fadeOff}
          onPlaceholderClick={(id) =>
            onPlaceholderClicked(visiblePlaceholders[id])
          }
          onPlaceholderHovered={(hoveredElement) =>
            setIsHovered(!!hoveredElement)
          }
        />
      )}
    </group>
  );
}
