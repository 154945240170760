import { IDENTITY } from "@/alignment-tool/store/alignment-slice";
import { alignmentTransformToMatrix4 } from "@/alignment-tool/utils/alignment-transform";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import {
  selectAlignedLayerForLayerToLayerAlignment,
  selectIncrementalSheetTransformForLayerToLayerAlignment,
  selectReferenceForLayerToLayerAlignment,
} from "@/store/modes/layer-to-layer-alignment-mode-selectors";
import { resetLayerToLayerAlignment } from "@/store/modes/layer-to-layer-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { selectAncestor } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectIElement,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import { createMutationAlignSheetWithSheet } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { LayerToLayerAlignmentProgressBar } from "./layer-to-layer-alignment-progress-bar";
import { LayerToLayerAlignmentSplitScreen } from "./layer-to-layer-alignment-split-screen";

/** @returns The overlay for layer-to-layer alignment mode */
export function LayerToLayerAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const store = useAppStore();

  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const alignedLayerId = useAppSelector(
    selectAlignedLayerForLayerToLayerAlignment,
  );

  const referenceLayerId = useAppSelector(
    selectReferenceForLayerToLayerAlignment,
  );

  const alignedLayer = useAppSelector(selectIElement(alignedLayerId));
  const referenceLayer = useAppSelector(selectIElement(referenceLayerId));
  if (
    !alignedLayerId ||
    !alignedLayer ||
    !referenceLayerId ||
    !referenceLayer
  ) {
    throw new Error("alignedLayer or referenceLayer not defined.");
  }

  const referenceLayerWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(referenceLayerId),
  );

  const alignedLayerWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(alignedLayerId),
  );

  const applyLayerMutation = useCallback(async () => {
    setShowSpinner(true);

    const incrementalTransform =
      selectIncrementalSheetTransformForLayerToLayerAlignment(
        store.getState(),
      ) ?? IDENTITY;

    const incrementalMatrix = alignmentTransformToMatrix4(incrementalTransform);

    const newWorldTransformMovingSheet = incrementalMatrix.multiply(
      alignedLayerWorldMatrix,
    );

    // get local pose of the aligned layer ielement
    const { pos, rot, scale } = selectIElementProjectApiLocalPose(
      referenceLayer,
      newWorldTransformMovingSheet,
    )(store.getState());

    try {
      await client.applyMutations([
        createMutationAlignSheetWithSheet(alignedLayerId, referenceLayerId, {
          pos,
          rot,
          scale,
          isWorldRot: false,
          gps: null,
        }),
      ]);
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });
    }

    const sectionArea = selectAncestor(
      alignedLayer,
      isIElementAreaSection,
    )(store.getState());
    if (!sectionArea) {
      throw new Error("Section Area not found for selected aligned sheet.");
    }

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      fetchProjectIElements({
        fetcher: async () =>
          // Refresh the area node to get new transform
          await client.getAllIElements({
            ancestorIds: [sectionArea.id],
          }),
      }),
    );

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetLayerToLayerAlignment());

    // after new alignment applied user will be redirected to 3D view in "overview" mode
    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(alignedLayerId));

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to 2D mode as most convenient to validate alignment result
    dispatch(changeMode("sheet"));
  }, [
    store,
    alignedLayer,
    dispatch,
    alignedLayerId,
    openToast,
    client,
    referenceLayerId,
    handleErrorWithToast,
  ]);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <LayerToLayerAlignmentProgressBar apply={applyLayerMutation} />
        <LayerToLayerAlignmentSplitScreen />
      </Stack>
    </>
  );
}
