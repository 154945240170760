import {
  ChangeActiveLayerProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useCurrentScene } from "@/modes/mode-data-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { changeMode } from "@/store/mode-slice";
import { setLayerAreaElementId } from "@/store/modes/alignment-wizard-mode-slice";
import { setActiveSheets } from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  FaroIconButton,
  FaroMenu,
  FaroMenuProps,
  FaroPopover,
  FaroText,
  InfoIcon,
  LayoutSheetSmallIcon,
  LocationMapIcon,
  NoTranslate,
  PlusIcon,
  SingleSelectItem,
  SingleSelectItemProps,
  TranslateVar,
  convertToDateString,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElementAreaSection,
  IElementGenericDataset,
  IElementGenericImgSheet,
  isIElementAreaSection,
  isIElementGenericDataset,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { useCallback, useMemo, useState } from "react";
import { ElementIcon, ElementIconType } from "./icons";
import { ContextMenu } from "./tree/tree-context-menu/tree-context-menu";

export type MultiLayerMenuProps = Pick<
  FaroMenuProps,
  "open" | "anchorEl" | "onClose"
>;

/** @returns a PopOver to open from a menu to change the sheet rendered in the scene*/
export function MultiLayerMenu({
  open,
  anchorEl,
  onClose,
}: MultiLayerMenuProps): JSX.Element {
  const { availableSheets, activeSheet: currentSheet } = useCurrentScene();

  const parentArea = useAppSelector(
    selectAncestor(currentSheet, isIElementAreaSection),
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const dispatch = useAppDispatch();
  const canAddLayer = useAppSelector(selectHasFeature(Features.AlignLayer));
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const shouldBeDarkStyle = true;

  // All layers visibility is now attached to the same state.
  // Switching one state in UI will switch to the same state all of them
  // support of individual layers visibility will be done in https://faro01.atlassian.net/browse/CADBIM-1119
  const [visibleLayers, setVisibleLayers] = useState(true);

  return (
    <FaroMenu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -2,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      dark={shouldBeDarkStyle}
    >
      <Stack gap={1} p={1} sx={{ minWidth: "291px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <FaroText variant="heading16" color={neutral[100]}>
            Layers
          </FaroText>

          {canAddLayer && parentArea && (
            <Stack direction="row">
              <FaroIconButton
                size="s"
                aria-label="layer-info"
                ref={setButtonRef}
                onClick={() => {
                  setIsPopoverOpen(true);
                }}
              >
                <InfoIcon sx={{ stroke: neutral[400] }} />
              </FaroIconButton>

              {hasWritePermission && (
                <Tooltip title="Add layer">
                  <FaroIconButton
                    size="s"
                    aria-label="add-layer"
                    onClick={() => {
                      dispatch(setLayerAreaElementId(parentArea.id));
                      dispatch(changeMode("importSheet"));
                    }}
                  >
                    <PlusIcon sx={{ stroke: neutral[400] }} />
                  </FaroIconButton>
                </Tooltip>
              )}
            </Stack>
          )}
        </Stack>
        {availableSheets.map((sheet) => (
          <MultiLayerMenuItem
            sheet={sheet}
            key={sheet.id}
            isVisible={visibleLayers}
            toggleLayerVisibility={() => setVisibleLayers(!visibleLayers)}
            onClick={() => {
              // TODO: will allow to set more than one active sheet in https://faro01.atlassian.net/browse/CADBIM-1119
              dispatch(setActiveSheets([sheet.id]));
            }}
          />
        ))}

        <FaroPopover
          anchorEl={buttonRef}
          open={isPopoverOpen}
          closeOnClickOutside
          dark={shouldBeDarkStyle}
          description={
            <>
              <p>Collection of sheets and overview maps.</p>
              <p>
                Sheets are available for each dataset contained in the active
                area, overview maps are limited to their dataset.
              </p>
            </>
          }
          onClose={() => {
            setIsPopoverOpen(false);
          }}
          placement="bottom"
          title="Layers"
        />
      </Stack>
    </FaroMenu>
  );
}

type MultiLayerMenuItemProps = Pick<SingleSelectItemProps, "selectedValue"> & {
  /** The sheet of this layer */
  sheet: IElementGenericImgSheet;

  /** Method to call when an item is selected */
  onClick?(): void;

  /** Flag used to show correct eye icon in menu: Visible/NonVisible  */
  isVisible: boolean;

  /**
   * Toggle visibility of layer: Visible/NonVisible
   *
   * @param layerId layer id for which which user clicked on eye button
   */
  toggleLayerVisibility(layerId: GUID): void;
};

/** @returns the select item for each available layer */
function MultiLayerMenuItem({
  sheet,
  onClick,
  isVisible,
  toggleLayerVisibility,
  ...rest
}: MultiLayerMenuItemProps): JSX.Element {
  const reference = useAppSelector((state) =>
    selectLayerReference(state, sheet),
  );

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const trackAndHandleClick = useCallback(() => {
    const type = reference?.typeHint ?? sheet.typeHint;

    if (type) {
      Analytics.track<ChangeActiveLayerProperties>(
        EventType.changeActiveLayer,
        {
          type,
        },
      );
    }

    onClick?.();
  }, [onClick, reference, sheet]);

  const canManageLayersVisibility = useAppSelector(
    selectHasFeature(Features.LayersVisibility),
  );

  return (
    <SingleSelectItem
      {...rest}
      onClick={trackAndHandleClick}
      label={<NoTranslate>{sheet.name}</NoTranslate>}
      extraAction={
        <Stack direction="row">
          {canManageLayersVisibility && (
            <EyeButton
              isVisible={isVisible}
              onClick={() => toggleLayerVisibility(sheet.id)}
            />
          )}
          {hasWritePermission && (
            <ContextMenu id={sheet.id} isNodeDisabled={false} dark />
          )}
        </Stack>
      }
      key={sheet.id}
      value={sheet.id}
      secondaryText={
        reference ? (
          <MultiLayerMenuItemSecondaryText reference={reference} />
        ) : undefined
      }
      dark
    />
  );
}

type MultiLayerMenuItemSecondaryTextProps = {
  /** The reference element the current layer is part of */
  reference: IElementGenericDataset | IElementAreaSection;
};

/** @returns the secondary text for a layer menu select item */
function MultiLayerMenuItemSecondaryText({
  reference,
}: MultiLayerMenuItemSecondaryTextProps): JSX.Element {
  const text = useMemo(() => {
    if (isIElementAreaSection(reference)) {
      return "Sheet";
    }
    return (
      <TranslateVar name="layerName">
        {convertToDateString(reference.createdAt)} {reference.name}
      </TranslateVar>
    );
  }, [reference]);

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <LayerIcon reference={reference} sx={{ fontSize: "1em" }} />
      {text}
    </Stack>
  );
}

type LayerIconProps = {
  /** The reference element for the layer */
  reference: IElementGenericDataset | IElementAreaSection;
  /** Custom style properties */
  sx?: SxProps<Theme>;
};

/** @returns the icon component for a layer */
function LayerIcon({ reference, sx }: LayerIconProps): JSX.Element | null {
  if (isIElementAreaSection(reference)) {
    return <LayoutSheetSmallIcon sx={sx} />;
  } else if (isIElementGenericDataset(reference)) {
    return <LocationMapIcon sx={sx} />;
  }
  return null;
}

/**
 * @param state the app state
 * @param sheet the sheet to compute the reference for
 * @returns the reference element for a layer (area or dataset)
 */
const selectLayerReference = createSelector(
  [
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementGenericDataset)(state),
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementAreaSection)(state),
  ],
  (dataSet, area) => dataSet ?? area,
);

type EyeButtonProps = {
  /** Method to call when an item is selected */
  onClick(): void;

  /** Flag used to show correct eye icon in menu: Visible/NonVisible  */
  isVisible: boolean;
};

/** @returns render eye button */
function EyeButton({ isVisible, onClick }: EyeButtonProps): JSX.Element {
  return (
    <FaroIconButton
      onClick={onClick}
      margin={0.5}
      size="s"
      aria-label="toggle layer visibility"
      // Get both the image color and its hover color from the container
      color="inherit"
      hoverColor="inherit"
      sx={{
        color: "inherit",
      }}
    >
      <ElementIcon
        icon={isVisible ? ElementIconType.Visible : ElementIconType.NonVisible}
      />
    </FaroIconButton>
  );
}
