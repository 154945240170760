import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { ActiveToolAndPicking, PickingToolsRef } from "@/tools/picking-tools";
import {
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { Quaternion, Vector3 as Vector3Prop } from "@react-three/fiber";
import { Box3, Plane, Vector3 } from "three";
import { WalkSceneAnimation } from "./walk-scene-animation";
import { WalkSceneStatic } from "./walk-scene-static";
import {
  ViewType,
  WalkSceneActiveElement,
  WalkSceneOverlayElement,
} from "./walk-types";

export type WalkSceneCoreProps = PickingToolsRef & {
  /** The current element of the scene */
  currentElement: WalkSceneActiveElement;

  /** The element we want to switch to */
  targetElement?: WalkSceneActiveElement;

  /** The current floor plan where the placeholders are placed */
  sheet?: IElementGenericImgSheet;

  /** The element that we want to render together with the current element */
  overlayElement?: WalkSceneOverlayElement;

  /** Which view is this: main view in walk mode, left view, or right view in splitscreen mode */
  viewType: ViewType;

  /** List of all the placeholders to show */
  placeholders: IElementImg360[];

  /** If true other panorama's placeholders will be rendered  */
  shouldShowPlaceholders: boolean;

  /** if provided quaternion should be applied while performing pano animation */
  targetQuaternion?: Quaternion;

  /** List of all the annotations for this area */
  annotations: IElementGenericAnnotation[];

  /** Describe the active tool and whether it requires picking */
  activeToolAndPicking: ActiveToolAndPicking;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Whether the 3d models should be rendered on demand */
  renderOnDemand: boolean;

  /** Callback executed when the animation finishes */
  onAnimationFinished(active: WalkSceneActiveElement): void;

  /** Signal that the scene changed the camera position by animating the camera to a new pano */
  onCameraMovedViaAnimation?(position: Vector3Prop): void;

  /**
   * Callback executed when the user walks to a different position,
   * by clicking on the floor of the point cloud or by clicking on a measurement icon
   */
  onUserWalkedTo(p: Vector3): void;

  /** Callback executed when the active element should be changed */
  onTargetElementChanged(element: WalkSceneActiveElement): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged(target: WayPointTarget): void;

  /** Optional callback to signal outside that the bounding box of all models has changed. */
  onAllModelsBoxChanged?(box: Box3): void;
};

/** @returns The rendering logic of the Walk mode */
export function WalkSceneCore({
  currentElement,
  targetElement,
  overlayElement,
  viewType,
  placeholders,
  shouldShowPlaceholders,
  targetQuaternion,
  annotations,
  sheet,
  activeToolAndPicking,
  clippingPlanes,
  renderOnDemand,
  onAnimationFinished,
  onUserWalkedTo,
  onCameraMovedViaAnimation,
  onTargetElementChanged,
  onAllModelsBoxChanged,
  onWayPointChanged,
  ...toolEvents
}: WalkSceneCoreProps): JSX.Element | null {
  if (targetElement) {
    /** If the target element is defined, we render an animation */
    return (
      <WalkSceneAnimation
        currentElement={currentElement}
        targetElement={targetElement}
        targetQuaternion={targetQuaternion}
        sheet={sheet}
        viewType={viewType}
        onAnimationFinished={onAnimationFinished}
        onCameraMoved={onCameraMovedViaAnimation}
      />
    );
  }
  /** If the target element is not defined, we render a static scene */
  return (
    <WalkSceneStatic
      currentElement={currentElement}
      overlayElement={overlayElement}
      sheet={sheet}
      viewType={viewType}
      placeholders={placeholders}
      shouldShowPlaceholders={shouldShowPlaceholders}
      activeToolAndPicking={activeToolAndPicking}
      annotations={annotations}
      clippingPlanes={clippingPlanes}
      renderOnDemand={renderOnDemand}
      onUserWalkedTo={onUserWalkedTo}
      onTargetElementChanged={onTargetElementChanged}
      onAllModelsBoxChanged={onAllModelsBoxChanged}
      onWayPointChanged={onWayPointChanged}
      {...toolEvents}
    />
  );
}
