import { ToSheetAnimation } from "@/components/r3f/animations/to-sheet-animation";
import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import { useCameraParametersIfAvailable } from "@/components/r3f/utils/camera-parameters";
import { useMapPlaceholderPositions } from "@/hooks/use-map-placeholder-positions";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectChildrenDepthFirst,
  selectIElementWorldTransform,
  Z_TO_Y_UP_QUAT,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Camera, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Box3, OrthographicCamera, Quaternion, Vector3 } from "three";
import { SheetModeInitialState } from "./sheet-state";

/** Height of the orthographic camera with respect to the bbox of the selected odometry path */
const Y_OFFSET = 100;

/**
 * When the orthocamera must be centered and framed on an odometry path's bbox, this amount
 * of padding is added to the path bbox to provide some margin to the visualization
 */
const PADDING = 2;

type CenterSheetCameraOnPathProps = {
  /** Orthocamera initialized in sheet mode */
  modeCamera: Camera;

  /** A section with 'DataSEtViewoWalk' type hint, representing an odometry path. */
  path: IElementSection;

  /** Current sheet */
  sheet?: IElementGenericImgSheet;

  /** Callback to warn the parent components that the correct camera has been computed. */
  onCameraUpdated(): void;
};

/** @returns a component that centers the orthocamera of sheet mode on an odometry path. */
function CenterSheetCameraOnPath({
  modeCamera,
  path,
  sheet,
  onCameraUpdated,
}: CenterSheetCameraOnPathProps): JSX.Element {
  const panos = useAppSelector(
    selectChildrenDepthFirst(path, isIElementImg360),
  );

  const positions = useMapPlaceholderPositions(panos, sheet);

  const { size } = useThree();

  const { quaternion: sheetQuaternion } = useAppSelector(
    selectIElementWorldTransform(sheet?.id),
  );

  // Computes the pose and projection of the orthocamera so that the selected path is zoomed on.
  useEffect(() => {
    assert(
      modeCamera instanceof OrthographicCamera,
      "Expected orthographic camera in sheet mode",
    );
    const box = new Box3();
    for (const p of positions) box.expandByPoint(p);
    box.expandByScalar(PADDING);
    const center = box.getCenter(new Vector3());
    const boxSize = box.getSize(new Vector3());
    const boxAr = boxSize.z / boxSize.x;

    modeCamera.position.copy(center);
    modeCamera.position.y += Y_OFFSET;

    const q = new Quaternion().fromArray(sheetQuaternion);
    modeCamera.quaternion.multiplyQuaternions(q, Z_TO_Y_UP_QUAT);

    const ar = size.height / size.width;
    if (boxAr > ar) {
      boxSize.x = boxSize.z / ar;
    } else {
      boxSize.z = boxSize.x * ar;
    }
    modeCamera.top = boxSize.z / 2;
    modeCamera.bottom = -modeCamera.top;
    modeCamera.right = boxSize.x / 2;
    modeCamera.left = -modeCamera.right;
    Object.assign(modeCamera, { manual: true });
    modeCamera.updateProjectionMatrix();
    modeCamera.updateMatrix();
    onCameraUpdated();
  }, [positions, modeCamera, onCameraUpdated, sheetQuaternion, size]);

  return <SnapshotRenderer />;
}

/**
 * @returns the transition to enter SheetMode
 * @param props - ModeTransitionProps required for transition between modes
 */
export function SheetTransition(
  props: ModeTransitionProps<SheetModeInitialState>,
): JSX.Element {
  const { initialState, modeCamera, onCompleted } = props;
  const { activeSheet, panos, paths } = useCurrentScene();

  useCameraParametersIfAvailable(modeCamera, initialState?.camera, onCompleted);

  if (initialState) {
    if (initialState.camera) {
      /** Bypass the ToSheetAnimation so the camera is not changed  */
      return <SnapshotRenderer />;
    } else if (initialState.lookAtId) {
      const lookAtPath = paths.find(
        (path) => path.id === initialState.lookAtId,
      );
      if (lookAtPath) {
        // If the deep link was looking to an odometry path, center the camera on it
        return (
          <CenterSheetCameraOnPath
            modeCamera={modeCamera}
            path={lookAtPath}
            sheet={activeSheet}
            onCameraUpdated={onCompleted}
          />
        );
      }
    }
  }

  return (
    <ToSheetAnimation
      placeholders={panos}
      sheetElement={activeSheet}
      {...props}
    />
  );
}
