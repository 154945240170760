import { useFlashScanIcons } from "@/components/r3f/renderers/odometry-paths/use-flash-scan-icons";
import {
  WaypointLabel,
  WaypointLabelRender,
  WaypointPosition,
} from "@/components/r3f/renderers/waypoint-label-render";
import { useMapPlaceholderPositions } from "@/hooks/use-map-placeholder-positions";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { selectIsPanoExtractedFromData } from "@/store/project-selector";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  selectObjectVisibility,
  selectWaypointsColoring,
} from "@/store/view-options/view-options-selectors";
import {
  ViewObjectTypes,
  WaypointsColoringOptions,
} from "@/store/view-options/view-options-slice";
import {
  INVALID_ALTITUDE_COLOR,
  MAX_ALTITUDE_COLOR,
  MIN_ALTITUDE_COLOR,
} from "@/utils/waypoints-color-gradient";
import {
  interpolateColor,
  isMapPlaceholdersUpdated,
  MapWaypointsRenderer,
  MayWaypointsRendererRef,
  selectAncestor,
  selectChildrenDepthFirst,
  selectIElementWorldPosition,
  State,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementImg360,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { PlaceholdersTexture } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Color, Plane } from "three";
import {
  useOffsetPlaceholders,
  useVisiblePlaceholders,
  useWaypoints,
} from "../../hooks/use-placeholders";
import { useWaypointAltitudeRange } from "../mode-data-context";
import { SheetModeRenderOrders } from "./sheet-mode-render-orders";

const FLASH_SCANS_LABEL = "Flash Scans";

/** Size of the default flash scan icon, in screen pixels without accounting for DPR */
const FLASH_SCANS_DEFAULT_SZ = 24;
/** Size of the hovered flash scan icon, in screen pixels without accounting for DPR */
const FLASH_SCANS_HOVERED_SZ = 34;

type SheetWaypointsProps = {
  /** The list of paths to render */
  paths: IElementSection[];

  /** The list of panoramas currently showed */
  panos: IElementImg360[];

  /** Sheet to render */
  sheetElement?: IElementGenericImgSheet;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Callback issued when a placeholder is clicked */
  onPlaceholderClicked?(el: IElementImg360): void;

  /** Callback issued when a placeholder is hovered */
  onPlaceholderHovered?(el?: IElementImg360): void;

  /** True to show label only when hover on the waypoint, otherwise show all waypoint labels */
  onlyShowLabelOnHover?: boolean;
};

/** @returns a component to instantiate the rendering of pano waypoints in sheet mode */
export function SheetWaypoints({
  paths,
  panos,
  sheetElement,
  clippingPlanes,
  onPlaceholderClicked,
  onPlaceholderHovered,
  onlyShowLabelOnHover = false,
}: SheetWaypointsProps): JSX.Element {
  const positions = useMapPlaceholderPositions(panos, sheetElement);

  const colors = useWaypointsColors(panos);

  const panoOffset = usePanoOffset(paths, sheetElement);

  const { visiblePlaceholders, visiblePositions } = useVisiblePlaceholders({
    placeholders: panos,
    positions,
    clippingPlanes,
  });

  const { placeholdersOffset, shiftedPlaceholders } =
    useOffsetPlaceholders(visiblePositions);

  const waypoints = useWaypoints(
    visiblePlaceholders,
    visiblePositions,
    panoOffset,
  );

  const labelContainer = useViewOverlayRef();
  const [hoveredWaypoint, setHoveredWaypoint] = useState<WaypointPosition>();
  const onWaypointHovered = useCallback(
    (el?: number) => {
      setHoveredWaypoint(el === undefined ? undefined : waypoints[el]);
      onPlaceholderHovered?.(el === undefined ? undefined : waypoints[el].pano);
    },
    [onPlaceholderHovered, waypoints],
  );

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  const flashScanIds = useAppSelector(
    selectFlashScanIds(visiblePlaceholders),
    isEqual,
  );

  const [mapPlaceholders, setMapPlaceholders] =
    useState<MayWaypointsRendererRef | null>();

  const { flashDefault, flashHovered } = useFlashScanIcons();

  const gl = useThree((s) => s.gl);

  useEffect(() => {
    if (!isMapPlaceholdersUpdated(mapPlaceholders, shiftedPlaceholders)) return;
    const dpr = gl.getPixelRatio();
    mapPlaceholders.setLabeledPlacehoders(FLASH_SCANS_LABEL, flashScanIds);
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Default,
      flashDefault,
    );
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Hovered,
      flashHovered,
    );
    mapPlaceholders.setLabeledMap(
      FLASH_SCANS_LABEL,
      PlaceholdersTexture.Selected,
      flashHovered,
    );
    mapPlaceholders.setLabelSizes(FLASH_SCANS_LABEL, {
      default: FLASH_SCANS_DEFAULT_SZ * dpr,
      hovered: FLASH_SCANS_HOVERED_SZ * dpr,
      selected: FLASH_SCANS_HOVERED_SZ * dpr,
    });
  }, [
    mapPlaceholders,
    flashScanIds,
    flashDefault,
    flashHovered,
    gl,
    shiftedPlaceholders,
  ]);

  const shouldDisplayWaypointLabels = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypointLabels),
  );

  const onWaypointClicked = useCallback(
    (index: number) => {
      onPlaceholderClicked?.(waypoints[index].pano);
    },
    [waypoints, onPlaceholderClicked],
  );

  return (
    <>
      {/* Place panorama images above waypoints in odometric paths, to increase their visibility */}
      <group position-y={panoOffset}>
        <group position={placeholdersOffset}>
          <MapWaypointsRenderer
            customColors={colors}
            visible={shouldWayPointsBeVisible}
            waypoints={shiftedPlaceholders}
            onPlaceholderClick={onWaypointClicked}
            onPlaceholderHovered={onWaypointHovered}
            renderOrder={SheetModeRenderOrders.Waypoints}
            ref={setMapPlaceholders}
          />
        </group>
      </group>
      {
        // Render all waypoint labels
        !onlyShowLabelOnHover &&
          shouldWayPointsBeVisible &&
          shouldDisplayWaypointLabels && (
            <WaypointLabelRender
              waypoints={waypoints}
              onLabelClick={onPlaceholderClicked}
            />
          )
      }
      {
        // Render label on hovered waypoint
        onlyShowLabelOnHover &&
          hoveredWaypoint !== undefined &&
          shouldWayPointsBeVisible &&
          shouldDisplayWaypointLabels && (
            <WaypointLabel
              key={hoveredWaypoint.pano.id}
              pano={hoveredWaypoint.pano}
              position={hoveredWaypoint.renderPosition}
              parentRef={labelContainer}
              disablePointerEvents
            />
          )
      }
    </>
  );
}

/**
 * @param paths The trajectories in the sheet
 * @param sheetElement The area sheet being displayed
 * @returns the offset to apply to the pano placeholders to ensure they are rendered above the trajectory panos
 */
function usePanoOffset(
  paths: IElementSection[],
  sheetElement?: IElementGenericImgSheet,
): number {
  const pathPanos = useAppSelector(
    (state) =>
      paths.flatMap((path) =>
        selectChildrenDepthFirst(path, isIElementImg360)(state),
      ),
    isEqual,
  );
  const pathPanoPositions = useMapPlaceholderPositions(pathPanos, sheetElement);
  // To avoid z-fighting and to account for additional layer offset of the trajectory panos
  const additionalOffset = 1;
  return (
    additionalOffset +
    useMemo(
      () => Math.max(0, ...pathPanoPositions.map((pos) => pos.y)),
      [pathPanoPositions],
    )
  );
}

/**
 * @returns The waypoints color based on altitude, if the option is enabled
 * @param panos The list of all panos in the scene
 */
function useWaypointsColors(panos: IElementImg360[]): Color[] | undefined {
  const coloring = useAppSelector(selectWaypointsColoring);

  const range = useWaypointAltitudeRange();

  const { getState } = useAppStore();
  return useMemo(() => {
    switch (coloring) {
      case WaypointsColoringOptions.default:
        return;
      case WaypointsColoringOptions.byElevation: {
        if (!range) return;
        // Coloring the waypoints by altitude
        const state = getState();
        const positions = panos.map((p) =>
          selectIsPanoExtractedFromData(p)(state)
            ? selectIElementWorldPosition(p.id)(state)
            : undefined,
        );

        return positions.map((p) => {
          if (!p) return new Color(INVALID_ALTITUDE_COLOR);

          const alpha = (p[1] - range.lowest) / (range.highest - range.lowest);
          return new Color(
            interpolateColor(MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR, alpha),
          );
        });
      }
      case WaypointsColoringOptions.byCaptureDate: {
        // Coloring the waypoints by date
        return waypointsColorsByDate(panos);
      }
    }
  }, [getState, panos, range, coloring]);
}

/**
 *
 * @param panos The list of panos
 * @returns The list of colors for waypoints, representing the panos' capture date.
 */
function waypointsColorsByDate(panos: IElementImg360[]): Color[] | undefined {
  const dates = panos.map((p) => DateTime.fromISO(p.createdAt));
  const minDate = DateTime.min(...dates).toMillis();
  const maxDate = DateTime.max(...dates).toMillis();
  if (minDate >= maxDate) return;
  const invInterval = 1 / (maxDate - minDate);
  return dates.map((d) => {
    const alpha = (d.toMillis() - minDate) * invInterval;
    return new Color(
      interpolateColor(MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR, alpha),
    );
  });
}

/**
 * @returns Which panos of the input list are flash scans
 * @param panos The list of panos to filter
 */
function selectFlashScanIds(panos: IElementImg360[]) {
  return (state: State) => {
    const ret: number[] = [];

    for (let panoId = 0; panoId < panos.length; panoId++) {
      const pano = panos[panoId];
      const isFlash = !!selectAncestor(pano, (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.flash,
        ),
      )(state);
      if (isFlash) ret.push(panoId);
    }

    return ret;
  };
}
