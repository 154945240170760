import { useAppSelector } from "@/store/store-hooks";
import { selectObjectVisibility } from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import {
  IElement,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
} from "@faro-lotv/ielement-types";
import { ThreeEvent } from "@react-three/fiber";
import { Box3, Plane } from "three";
import { OdometryPathRenderer } from "./odometry-path-renderer";

type Props = {
  /** The paths to render*/
  paths: IElementSection[];

  /** Current sheet to render the paths for */
  activeSheet?: IElementGenericImgSheet;

  /** The current active path */
  activePath?: IElement;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Callback when a pano placeholder is hovered */
  onPlaceholderHovered?(pano: IElementImg360): void;

  /** Callback when a pano placeholder is clicked */
  onPlaceholderClicked?(pano: IElementImg360): void;

  /** Callback when the path is activated */
  onPathClick?(
    event: ThreeEvent<MouseEvent>,
    path: IElementSection,
    boundingBox: Box3,
  ): void;
};

/**
 * @returns the odometric paths for the currently active day
 */
export function OdometryPathsRenderer({
  paths,
  activeSheet,
  activePath,
  clippingPlanes,
  onPlaceholderHovered,
  onPlaceholderClicked,
  onPathClick,
}: Props): JSX.Element | null {
  const shouldTrajectoriesBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.trajectories),
  );

  if (!paths.length || !shouldTrajectoriesBeVisible) {
    return null;
  }

  return (
    <>
      {paths.map((path) => (
        <OdometryPathRenderer
          key={path.id}
          path={path}
          sheet={activeSheet}
          activeRecording={activePath}
          onPathClick={onPathClick}
          onPlaceholderClicked={onPlaceholderClicked}
          onPlaceholderHovered={onPlaceholderHovered}
          clippingPlanes={clippingPlanes}
        />
      ))}
    </>
  );
}
